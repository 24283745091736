import React, { useEffect, useState }  from 'react';

import Place from "../../ListView/PlacesList/Place";

import './index.sass';


const PopularPlaces = (props) => {

  const [descriptionWidth, setDescriptionWidth] = useState(undefined);

  const places = props.places.slice(0, 4);

  useEffect(() => {
    window.addEventListener('orientationchange', handleDescriptionWidth);
    window.addEventListener('resize', handleDescriptionWidth);
    return () => {
      window.removeEventListener('orientationchange', handleDescriptionWidth);
      window.removeEventListener('resize', handleDescriptionWidth);
    }
  }, [places]);

  useEffect(() => {
    handleDescriptionWidth();
  }, [places]);


  const handleDescriptionWidth = () => {
    setTimeout(() => {
      if (places && places.length) {
        const placeId = places[0].id;
        const placeWrapper = document.getElementById(`place-wrapper-${placeId}`);
        const wrapperWidth = placeWrapper && placeWrapper.offsetWidth;

        wrapperWidth && setDescriptionWidth(wrapperWidth);
      }
    }, 0);
  };


  return (
    <div className="popular-places">
      <div className="popular-places__top container">
        <div className="popular-places__headline">
          Populære steder
        </div>
        <a href="/leje">Se alle</a>
      </div>

      <div className="slider-container">
        <div className="slider-wrapper container">
          <div className="popular-places__list-wrapper">
            { places.map(place =>
              <div className="popular-places__place-wrapper" key={place.id} id={`place-wrapper-${place.id}`}>
                <Place place={place} descriptionWidth={descriptionWidth} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopularPlaces;
