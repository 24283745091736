import React from 'react';

import { prepareOptionsForLocationSearch } from "../../src/js/helpers";
import TopMenu from "../SharedComponents/TopMenu";
import Footer from "../SharedComponents/Footer";

import './index.sass';


const ErrorPage = ({ categories, errorCode, footer, regions, topMenu }) => {

  const regionsOptions = prepareOptionsForLocationSearch(regions);

  const errorDescription = () => {
    switch (errorCode) {
      case 404:
        return 'Siden kan ikke finds. Måske selskabslokalet er blevet fjernet?';
      case 422:
        return 'Den forstår ikke helt hvad du gerne vil. Kontakt os hvis problemet fortsætter.';
      case 500:
        return 'Vores programmør har muligvis lavet en programmeringsfejl. Serveren har sendt ham en e-mail nu, så kig tilbage om et par timer og se om ikke fejlen er blevet rettet.';
      default:
        return '';
    };
  };


  return (
    <div className="error-page">

      <TopMenu
        {...topMenu}
        categories={categories}
        regionsOptions={regionsOptions}
        withSearch={true}
      />

      <div className="container">
        <div className="error-page__content-wrapper">
          <h1>Øv... Server-fejl HTTP { errorCode }</h1>

          <div className="error-page__error-description">{ errorDescription() }</div>

          <div className="error-page__error-description">Klik <a href="/">her</a> for at komme til forsiden.</div>
        </div>
      </div>

      <Footer { ...footer } />

    </div>
  )
};


export default ErrorPage;
