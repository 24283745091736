import React, { useState } from 'react';

import SvgIcon from "../Icons";

import './index.sass';


const FAQ = ({ data }) => {

  const [activeTabId, setActiveTabId] = useState(null);

  const toggleDropdown = (newActiveTabId) => {
    const activeTab = activeTabId && document.getElementById(activeTabId);
    const newActiveTab = document.getElementById(newActiveTabId);

    !activeTabId && newActiveTab.classList.add('open');

    if (activeTabId) {

      if (activeTabId === newActiveTabId) {
        activeTab.classList.toggle('open');
        activeTab.classList.toggle('close');
      } else {
        activeTab.classList.remove('open');
        activeTab.classList.add('close');
        newActiveTab.classList.remove('close');
        newActiveTab.classList.add('open');
      }
    }

    setActiveTabId(newActiveTabId);
  };

  if (data && data.length) {
    return (
      <div className="faq">
        <div className="faq__headline">
          FAQ
        </div>
        <div className="faq__list">
          { data.map((faq, index) =>
            <div key={`faq${index}`} className="faq__list-item" id={`faqTrigger${index}`} onClick={() => toggleDropdown(`faqTrigger${index}`)}>
              <h3>
                { faq.question }
                <SvgIcon name="expand_more" size={16} />
              </h3>
              <p className="faq__list-item-body">
                { faq.answer }
              </p>
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return null;
  }
};

export default FAQ;
