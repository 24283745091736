import React from 'react';

import DefaultGuides from "../../../src/images/entrypage/default_guides.svg";

import './index.sass';


const Guides = ({ guides, guidesUrl }) => {

  return (
    <div className="guides">
      <div className="guides__top container">
        <div className="guides__headline">
          Guides til din fest
        </div>
        <a href={guidesUrl} target="_blank">Se alle</a>
      </div>

      <div className="slider-container">
        <div className="slider-wrapper container">
          <div className="guides__list row">
            { guides.map(guide =>
              <div className="col-lg-6 guides__guide" key={`guide${guide.id}`}>
                <span className={guide.slug}>
                  <a href={guide.url || '#'} className="guides__guide-link">
                    <img alt={guide.title}
                         className={`guides__guide-image lazyload ${guide.image ? '' : 'default'}`}
                         data-src={guide.image || DefaultGuides}
                         data-expand="100"
                         data-sizes="auto" />
                    <span>{ guide.title }</span>
                    <div className="guides__guide-background" />
                  </a>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Guides;
