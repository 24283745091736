import PropTypes from 'prop-types';
import React from 'react';

import { prepareOptionsForLocationSearch } from "../../src/js/helpers";
import TopMenu from '../SharedComponents/TopMenu';
import FAQ from "../SharedComponents/FAQ";
import Footer from "../SharedComponents/Footer";
import Benefits from '../SharedComponents/Benefits';
import PopularPlaces from "./PopularPlaces";
import Guides from "./Guides";
import WhyUs from "./WhyUs";
import SearchSection from "./SearchSection";

import './index.sass';


const EntryPage = (props) => {

  const { categories, galleryPhotos, guides, faq, footer, popularPlaces, regions, topMenu } = props;

  const regionsOptions = prepareOptionsForLocationSearch(regions);


  return (
    <div className="entry-page">
      <TopMenu {...topMenu} />

      <main className="entry-main">
        <section>
          <SearchSection
            categories={categories}
            galleryPhotos={galleryPhotos}
            regionsOptions={regionsOptions}
          />
        </section>
      </main>

      <section>
        <Benefits />
      </section>

      <section>
        <PopularPlaces places={popularPlaces} />
      </section>

      <section>
        <Guides guides={guides} guidesUrl={topMenu.guidesPath} />
      </section>

      <section>
        <WhyUs />
      </section>

      <section className="container">
        <FAQ data={faq} />
      </section>

      <Footer { ...footer } />
    </div>
  );
};

EntryPage.propTypes = {
  // name: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default EntryPage;
