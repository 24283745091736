import React from 'react';

import Jakob from "../../../src/images/contacts/jakob.jpeg";
import SvgIcon from "../../SharedComponents/Icons";

import './index.sass';


const WhyUs = ({}) => {

  return (
    <div className="why-us">
      <div className="container">
        <div className="why-us__wrapper row">
          <div className="why-us__content-wrapper col-12 col-lg-6 col-xl-6">
            <div className="why-us__header">
              Hvorfor bruge <span>Selskabslokaler.dk</span>?
            </div>

            <div className="why-us__list">
              <div className="why-us__list-item">
                <SvgIcon name="check_outlined" size={24} />
                Mere end 900 lokationer i hele Danmark
              </div>
              <div className="why-us__list-item">
                <SvgIcon name="check_outlined" size={24} />
                Nemt, hurtigt og sikkert
              </div>
              <div className="why-us__list-item">
                <SvgIcon name="check_outlined" size={24} />
                Personlig assistance via mail og telefon
              </div>
              <div className="why-us__list-item">
                <SvgIcon name="check_outlined" size={24} />
                Skræddersyede løsninger efter dine behov
              </div>
              <div className="why-us__list-item">
                <SvgIcon name="check_outlined" size={24} />
                Stor tilfredshed blandt besøgende
              </div>
            </div>

            <div className="why-us__search-button" onClick={() => window.scrollTo(0, 0)}>
              <SvgIcon name="search" size={16} />
              Start din søgning
            </div>
          </div>

          <div className="col-lg-6 col-xl-6">
            <img alt="Jakob"
                 className="why-us__jakob lazyload"
                 data-expand="100"
                 data-sizes="auto"
                 data-src={Jakob} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyUs;
