import React from 'react';

import SvgIcon from "../Icons";

import './index.sass'


const Benefits = ({ shortened }) => {

  return (
    <div className="benefits">

      <div className="slider-container">
        <div className="slider-wrapper">

          { !shortened &&
            <div className="benefits__item-wrapper">
              <div className="benefits__item">
                <SvgIcon name="users" size={32} />
                <p>40.000 besøgende om måneden</p>
              </div>
            </div>
          }

          <div className="benefits__item-wrapper">
            <div className="benefits__item">
              <SvgIcon name="globe" size={32} />
              <p>Danmarks største udvalg</p>
            </div>
          </div>

          <div className="benefits__item-wrapper">
            <div className="benefits__item">
              <SvgIcon name="houses" size={32} />
              <p>Find lokale til alle lejligheder</p>
            </div>
          </div>

          <div className="benefits__item-wrapper">
            <div className="benefits__item">
              <SvgIcon name="verified" size={32} />
              <p>100% gratis og uforpligtende</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Benefits;
